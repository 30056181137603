window.loadChatbot = (function () {
  const head = document.getElementsByTagName('head')[0];
  const newScriptTag = document.createElement('script');
  newScriptTag.src = '//js.hs-scripts.com/8949671.js';
  newScriptTag.id = 'hs-script-loader';
  head.appendChild(newScriptTag);
});
window.chatIframeMutationObserver = (() => {
  const targetNode = document.body;
  const config = { childList: true };
  const callback = (_, observer) => {
    const iframe = document.getElementById('hubspot-messages-iframe-container');
    if (iframe) {
      document.documentElement.appendChild(iframe);
      observer.disconnect();
    }
  };

  const observer = new MutationObserver(callback);

  if (!document.querySelector('html > #hubspot-messages-iframe-container')) {
    observer.observe(targetNode, config);
  }
});

window.addEventListener('turbolinks:load', () => {
  const script = document.getElementById('hs-script-loader');
  if (!script) {
    window.loadChatbot();
  }
  window.chatIframeMutationObserver();
});
